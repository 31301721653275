<style scoped></style>
<template>
  <div>
    <v-card elevation="5">
      <v-card-text class="pa-0 font-weight-bold">
        <div>
          <div class="primary_2 d-flex  white--text font-weight-bold px-2 mb-1">
            <div
              style="width: 75%"
              @click="toggleDocumentAccordion()"
              class="d-flex align-center py-3"
            >
              <div class="text-body-1 font-weight-medium px-3">
                {{ document_accordion.title }}
              </div>
            </div>
            <div style="width: 25%" class="d-flex align-center justify-end">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    outlined
                    color="white"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    Add Doc
                    <v-icon color="white" class="ml-1" small
                      >mdi-chevron-down</v-icon
                    >
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in add_document_list"
                    :key="index"
                    class="doc-item"
                    @click="openAddDocDialog(item)"
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn fab small text @click="toggleDocumentAccordion()">
                <v-icon color="white" v-if="document_accordion.show">
                  mdi-chevron-up
                </v-icon>
                <v-icon color="white" v-else>
                  mdi-chevron-down
                </v-icon>
              </v-btn>
            </div>
          </div>
          <div v-if="document_accordion.show" transition="scroll-y-transition">
            <div class="pb-6 px-3 pt-3">
              <!-- sub accordions -->
              <div v-for="item in sub_accordion_header" :key="item.title">
                <div
                  class="blue-grey lighten-4 d-flex  black--text font-weight-bold px-2 mb-1"
                  @click="toggleSubAccordion(item)"
                >
                  <div style="width: 75%" class="d-flex align-center py-3">
                    <div class="text-body-1 font-weight-medium px-3">
                      {{ item.title }}
                    </div>
                  </div>
                  <div
                    style="width: 25%"
                    class="d-flex align-center justify-end"
                  >
                    <v-icon color="black" v-if="item.show">
                      mdi-chevron-up
                    </v-icon>
                    <v-icon color="black" v-else>
                      mdi-chevron-down
                    </v-icon>
                  </div>
                </div>
                <!-- KYC of Applicant content-->
                <div v-if="item.title == 'KYC of Applicant' && item.show">
                  <v-row v-if="applicants_kyc_data">
                    <v-col cols="12">
                      <v-row class=" ma-0 document-row">
                        <v-col cols="4" class="d-flex align-center">
                          <div class="field-title text-body-2">
                            Aadhaar Front & Back
                          </div>
                        </v-col>
                        <v-col class="d-flex justify-end align-center">
                          <v-btn
                            :disabled="
                              !applicants_kyc_data.aadhar_card_front_and_back
                            "
                            @click="
                              isImageDocument(
                                applicants_kyc_data.aadhar_card_front_and_back
                              )
                                ? previewImage(
                                    applicants_kyc_data.aadhar_card_front_and_back,
                                    'Aadhaar Front & Back'
                                  )
                                : viewDocument(
                                    applicants_kyc_data.aadhar_card_front_and_back
                                  )
                            "
                            small
                            text
                            class="indigo lighten-5 py-4"
                          >
                            <v-icon color="deep-purple darken-4"
                              >mdi-eye-outline</v-icon
                            >
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row class=" ma-0 document-row">
                        <v-col cols="4" class="d-flex align-center">
                          <div class="field-title text-body-2">
                            Aadhaar Front
                          </div>
                        </v-col>
                        <v-col class="d-flex justify-end align-center">
                          <v-btn
                            :disabled="
                              !applicants_kyc_data.aadhar_card_front_file
                            "
                            @click="
                              isImageDocument(
                                applicants_kyc_data.aadhar_card_front_file
                              )
                                ? previewImage(
                                    applicants_kyc_data.aadhar_card_front_file,
                                    'Aadhaar Front'
                                  )
                                : viewDocument(
                                    applicants_kyc_data.aadhar_card_front_file
                                  )
                            "
                            small
                            text
                            class="indigo lighten-5 py-4"
                          >
                            <v-icon color="deep-purple darken-4"
                              >mdi-eye-outline</v-icon
                            >
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row class=" ma-0 document-row">
                        <v-col cols="4" class="d-flex align-center">
                          <div class="field-title text-body-2">
                            Aadhaar Back
                          </div>
                        </v-col>
                        <v-col class="d-flex justify-end align-center">
                          <v-btn
                            :disabled="
                              !applicants_kyc_data.aadhar_card_back_file
                            "
                            @click="
                              isImageDocument(
                                applicants_kyc_data.aadhar_card_back_file
                              )
                                ? previewImage(
                                    applicants_kyc_data.aadhar_card_back_file,
                                    'Aadhaar Back'
                                  )
                                : viewDocument(
                                    applicants_kyc_data.aadhar_card_back_file
                                  )
                            "
                            small
                            text
                            class="indigo lighten-5 py-4"
                          >
                            <v-icon color="deep-purple darken-4"
                              >mdi-eye-outline</v-icon
                            >
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row class=" ma-0 document-row">
                        <v-col cols="4" class="d-flex align-center">
                          <div class="field-title text-body-2">
                            PAN Card
                          </div>
                        </v-col>
                        <v-col class="d-flex justify-end align-center">
                          <v-btn
                            :disabled="!applicants_kyc_data.pan_card_file"
                            @click="
                              isImageDocument(applicants_kyc_data.pan_card_file)
                                ? previewImage(
                                    applicants_kyc_data.pan_card_file,
                                    'PAN Card'
                                  )
                                : viewDocument(
                                    applicants_kyc_data.pan_card_file
                                  )
                            "
                            small
                            text
                            class="indigo lighten-5 py-4"
                          >
                            <v-icon color="deep-purple darken-4"
                              >mdi-eye-outline</v-icon
                            >
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row class=" ma-0 document-row mb-2">
                        <v-col cols="4" class=" d-flex align-center">
                          <div class="field-title text-body-2">
                            Selfie
                          </div>
                        </v-col>
                        <v-col class="d-flex justify-end align-center">
                          <v-btn
                            :disabled="!applicants_kyc_data.upload_photo_file"
                            @click="
                              isImageDocument(
                                applicants_kyc_data.upload_photo_file
                              )
                                ? previewImage(
                                    applicants_kyc_data.upload_photo_file,
                                    'Selfie'
                                  )
                                : viewDocument(
                                    applicants_kyc_data.upload_photo_file
                                  )
                            "
                            small
                            text
                            class="indigo lighten-5 py-4"
                          >
                            <v-icon color="deep-purple darken-4"
                              >mdi-eye-outline</v-icon
                            >
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col cols="12" class="d-flex align-center justify-center">
                      <div class="my-3">No Documents Available</div>
                    </v-col>
                  </v-row>
                </div>
                <div v-if="item.title == 'Current Account' && item.show">
                  <v-row v-if="saving_account_list.length != 0">
                    <v-col cols="12">
                      <v-row
                        class="ma-0 document-row mb-1 pl-4"
                        v-for="(doc, index) in saving_account_list"
                        :key="index"
                      >
                        <v-col cols="4">
                          <div class="d-flex align-center">
                            <div class="field-title text-body-2">
                              <span class="font-weight-black mr-2"
                                >{{ index + 1 }}.</span
                              >
                              {{ doc.document_type }}
                            </div>
                          </div>
                          <div
                            v-if="doc.document_password"
                            class="primary_2--text font-weight-bold text-caption"
                          >
                            <span>
                              <v-icon small class="success--text">
                                mdi-shield-key
                              </v-icon>
                            </span>
                            {{ doc.document_password }}
                          </div>
                        </v-col>
                        <v-col class="d-flex justify-end align-center">
                          <v-btn
                            :disabled="!doc.uploaded_file"
                            @click="
                              isImageDocument(doc.uploaded_file)
                                ? previewImage(
                                    doc.uploaded_file,
                                    'Current Account'
                                  )
                                : viewDocument(doc.uploaded_file)
                            "
                            small
                            text
                            class="indigo lighten-5 py-4"
                          >
                            <v-icon color="deep-purple darken-4"
                              >mdi-eye-outline</v-icon
                            >
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col cols="12" class="d-flex align-center justify-center">
                      <div class="my-3">No Documents Available</div>
                    </v-col>
                  </v-row>
                </div>
                <div v-if="item.title == 'Electricity Bill' && item.show">
                  <v-row v-if="electricity_bill_list.length != 0">
                    <v-col cols="12">
                      <v-row
                        class="ma-0 document-row mb-1 pl-4"
                        v-for="(doc, index) in electricity_bill_list"
                        :key="index"
                      >
                        <v-col cols="4" class="d-flex align-center">
                          <div class="field-title text-body-2">
                            <span class="font-weight-black mr-2"
                              >{{ index + 1 }}.</span
                            >
                            {{ doc.document_type }}
                          </div>
                        </v-col>
                        <v-col class="d-flex justify-end align-center">
                          <v-btn
                            :disabled="!doc.uploaded_file"
                            @click="
                              isImageDocument(doc.uploaded_file)
                                ? previewImage(
                                    doc.uploaded_file,
                                    'Electricity Bill'
                                  )
                                : viewDocument(doc.uploaded_file)
                            "
                            small
                            text
                            class="indigo lighten-5 py-4"
                          >
                            <v-icon color="deep-purple darken-4"
                              >mdi-eye-outline</v-icon
                            >
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col cols="12" class="d-flex align-center justify-center">
                      <div class="my-3">No Documents Available</div>
                    </v-col>
                  </v-row>
                </div>
                <div v-if="item.title == 'Sanction Letter' && item.show">
                  <v-row v-if="sanctioned_letter">
                    <v-col cols="12">
                      <v-row
                        class="ma-0 document-row mb-1 pl-4"
                        v-for="(doc, index) in sanctioned_letter"
                        :key="index"
                      >
                        <v-col cols="4" class="d-flex align-center">
                          <div class="field-title text-body-2">
                            <span class="font-weight-black mr-2"
                              >{{ index + 1 }}.</span
                            >
                            {{ doc.document_type }}
                          </div>
                        </v-col>
                        <v-col class="d-flex justify-end align-center">
                          <v-btn
                            :disabled="!doc.uploaded_file"
                            @click="
                              isImageDocument(doc.uploaded_file)
                                ? previewImage(
                                    doc.uploaded_file,
                                    'Sanction Letter'
                                  )
                                : viewDocument(doc.uploaded_file)
                            "
                            small
                            text
                            class="indigo lighten-5 py-4"
                          >
                            <v-icon color="deep-purple darken-4"
                              >mdi-eye-outline</v-icon
                            >
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col cols="12" class="d-flex align-center justify-center">
                      <div class="my-3">No Documents Available</div>
                    </v-col>
                  </v-row>
                </div>
                <div
                  v-if="
                    item.title == 'Business CC Account Statement' && item.show
                  "
                >
                  <v-row v-if="business_cc_details">
                    <v-col cols="12">
                      <v-row
                        class="ma-0 document-row mb-1 pl-4"
                        v-for="(doc, index) in business_cc_details"
                        :key="index"
                      >
                        <v-col cols="4">
                          <div class="d-flex align-center">
                            <div class="field-title text-body-2">
                              <span class="font-weight-black mr-2"
                                >{{ index + 1 }}.</span
                              >
                              {{ doc.document_type }}
                            </div>
                          </div>
                          <div
                            v-if="doc.document_password"
                            class="primary_2--text font-weight-bold text-caption"
                          >
                            <span>
                              <v-icon small class="success--text">
                                mdi-shield-key
                              </v-icon>
                            </span>
                            {{ doc.document_password }}
                          </div>
                        </v-col>
                        <v-col class="d-flex justify-end align-center">
                          <v-btn
                            :disabled="!doc.uploaded_file"
                            @click="
                              isImageDocument(doc.uploaded_file)
                                ? previewImage(
                                    doc.uploaded_file,
                                    'Business CC Account Statement'
                                  )
                                : viewDocument(doc.uploaded_file)
                            "
                            small
                            text
                            class="indigo lighten-5 py-4"
                          >
                            <v-icon color="deep-purple darken-4"
                              >mdi-eye-outline</v-icon
                            >
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col cols="12" class="d-flex align-center justify-center">
                      <div class="my-3">No Documents Available</div>
                    </v-col>
                  </v-row>
                </div>
                <div v-if="item.title == 'GST Certificate' && item.show">
                  <v-row v-if="gst_certificate.length != 0">
                    <v-col cols="12">
                      <v-row
                        class="ma-0 document-row mb-1 pl-4"
                        v-for="(doc, index) in gst_certificate"
                        :key="index"
                      >
                        <v-col cols="4" class="d-flex align-center">
                          <div class="field-title text-body-2">
                            <span class="font-weight-black mr-2"
                              >{{ index + 1 }}.</span
                            >
                            {{ doc.document_type }}
                          </div>
                        </v-col>
                        <v-col class="d-flex justify-end align-center">
                          <v-btn
                            :disabled="!doc.uploaded_file"
                            @click="
                              isImageDocument(doc.uploaded_file)
                                ? previewImage(
                                    doc.uploaded_file,
                                    'GST Certificate'
                                  )
                                : viewDocument(doc.uploaded_file)
                            "
                            small
                            text
                            class="indigo lighten-5 py-4"
                          >
                            <v-icon color="deep-purple darken-4"
                              >mdi-eye-outline</v-icon
                            >
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col cols="12" class="d-flex align-center justify-center">
                      <div class="my-3">No Documents Available</div>
                    </v-col>
                  </v-row>
                </div>
                <div v-if="item.title == 'Udyam Certificate' && item.show">
                  <v-row v-if="udyam_certificate.length != 0">
                    <v-col cols="12">
                      <v-row
                        class="ma-0 document-row mb-1 pl-4"
                        v-for="(doc, index) in udyam_certificate"
                        :key="index"
                      >
                        <v-col cols="4" class="d-flex align-center">
                          <div class="field-title text-body-2">
                            <span class="font-weight-black mr-2"
                              >{{ index + 1 }}.</span
                            >
                            {{ doc.document_type }}
                          </div>
                        </v-col>
                        <v-col class="d-flex justify-end align-center">
                          <v-btn
                            :disabled="!doc.uploaded_file"
                            @click="
                              isImageDocument(doc.uploaded_file)
                                ? previewImage(
                                    doc.uploaded_file,
                                    'Udyam Certificate'
                                  )
                                : viewDocument(doc.uploaded_file)
                            "
                            small
                            text
                            class="indigo lighten-5 py-4"
                          >
                            <v-icon color="deep-purple darken-4"
                              >mdi-eye-outline</v-icon
                            >
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col cols="12" class="d-flex align-center justify-center">
                      <div class="my-3">No Documents Available</div>
                    </v-col>
                  </v-row>
                </div>
                <div v-if="item.title == 'ITR with computation' && item.show">
                  <v-row v-if="itr.length != 0">
                    <v-col cols="12">
                      <v-row
                        class="ma-0 document-row mb-1 pl-4"
                        v-for="(doc, index) in itr"
                        :key="index"
                      >
                        <v-col cols="4" class="d-flex align-center">
                          <div class="field-title text-body-2">
                            <span class="font-weight-black mr-2"
                              >{{ index + 1 }}.</span
                            >
                            {{ doc.document_type }}
                          </div>
                        </v-col>
                        <v-col class="d-flex justify-end align-center">
                          <v-btn
                            :disabled="!doc.uploaded_file"
                            @click="
                              isImageDocument(doc.uploaded_file)
                                ? previewImage(
                                    doc.uploaded_file,
                                    'ITR with computation'
                                  )
                                : viewDocument(doc.uploaded_file)
                            "
                            small
                            text
                            class="indigo lighten-5 py-4"
                          >
                            <v-icon color="deep-purple darken-4"
                              >mdi-eye-outline</v-icon
                            >
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col cols="12" class="d-flex align-center justify-center">
                      <div class="my-3">No Documents Available</div>
                    </v-col>
                  </v-row>
                </div>
                <div v-if="item.title == 'Audit Report' && item.show">
                  <v-row v-if="audit_report.length != 0">
                    <v-col cols="12">
                      <v-row
                        class="ma-0 document-row mb-1 pl-4"
                        v-for="(doc, index) in audit_report"
                        :key="index"
                      >
                        <v-col cols="4" class="d-flex align-center">
                          <div class="field-title text-body-2">
                            <span class="font-weight-black mr-2"
                              >{{ index + 1 }}.</span
                            >
                            {{ doc.document_type }}
                          </div>
                        </v-col>
                        <v-col class="d-flex justify-end align-center">
                          <v-btn
                            :disabled="!doc.uploaded_file"
                            @click="
                              isImageDocument(doc.uploaded_file)
                                ? previewImage(
                                    doc.uploaded_file,
                                    'Audit Report'
                                  )
                                : viewDocument(doc.uploaded_file)
                            "
                            small
                            text
                            class="indigo lighten-5 py-4"
                          >
                            <v-icon color="deep-purple darken-4"
                              >mdi-eye-outline</v-icon
                            >
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col cols="12" class="d-flex align-center justify-center">
                      <div class="my-3">No Documents Available</div>
                    </v-col>
                  </v-row>
                </div>
                <div v-if="item.title == 'GST Return' && item.show">
                  <v-row v-if="gst_return.length != 0">
                    <v-col cols="12">
                      <v-row
                        class="ma-0 document-row mb-1 pl-4"
                        v-for="(doc, index) in gst_return"
                        :key="index"
                      >
                        <v-col cols="4" class="d-flex align-center">
                          <div class="field-title text-body-2">
                            <span class="font-weight-black mr-2"
                              >{{ index + 1 }}.</span
                            >
                            {{ doc.document_type }}
                          </div>
                        </v-col>
                        <v-col class="d-flex justify-end align-center">
                          <v-btn
                            :disabled="!doc.uploaded_file"
                            @click="
                              isImageDocument(doc.uploaded_file)
                                ? previewImage(doc.uploaded_file, 'GST Return')
                                : viewDocument(doc.uploaded_file)
                            "
                            small
                            text
                            class="indigo lighten-5 py-4"
                          >
                            <v-icon color="deep-purple darken-4"
                              >mdi-eye-outline</v-icon
                            >
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col cols="12" class="d-flex align-center justify-center">
                      <div class="my-3">No Documents Available</div>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <UploadKycDocDialog ref="upload_kyc_doc_dialog" @reload="getDocuments" />
    <UploadOtherDocDialog
      ref="upload_other_doc_dialog"
      @reload="getDocuments"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  components: {
    UploadKycDocDialog: () =>
      import("@/components/business-loan/dialog/AddEditKyc.vue"),
    UploadOtherDocDialog: () =>
      import("@/components/business-loan/dialog/AddOtherDocuments.vue"),
  },
  data() {
    return {
      //   document_accordion: {
      //     id: 1,
      //     title: "Basic Details",
      //     show: true,
      //   },

      are_all_collapsed: false,

      active_loan_id: null,
      is_other_doc_required: false,
      is_other_doc_verified: false,
      is_active_loan_available: false,
      sub_accordion_header: [
        {
          title: "KYC of Applicant",
          show: true,
        },
        {
          title: "Current Account",
          show: true,
        },
        {
          title: "Electricity Bill",
          show: true,
        },
        {
          title: "Sanction Letter",
          show: true,
        },
        {
          title: "Business CC Account Statement",
          show: true,
        },
        {
          title: "GST Certificate",
          show: true,
        },
        {
          title: "Udyam Certificate",
          show: true,
        },
        {
          title: "ITR with computation",
          show: true,
        },
        {
          title: "Audit Report",
          show: true,
        },
        {
          title: "GST Return",
          show: true,
        },
      ],
      saving_account_documents: [
        {
          id: 1,
          document_name: "Saving Acc June 2023 month",
          password: "85204",
          docuemnt_url: "www.google.com",
        },
        {
          id: 2,
          document_name: "Saving Acc July 2023 month",
          password: "85204",
          docuemnt_url: "www.google.com",
        },
      ],
      applicants_kyc_data: [],
      electricity_bill_list: [],
      audit_report: [],
      business_cc_details: [],
      gst_certificate: [],
      sanctioned_letter: [],
      gst_return: [],
      itr: [],
      saving_account_list: [],
      udyam_certificate: [],
      add_document_list: [
        { title: "KYC", key: "kyc" },
        { title: "Current Account", key: "bank_statement" },
        { title: "Electricity Bill", key: "electricity_bill" },
        { title: "Sanction Letter", key: "sanction_letter" },
        { title: "Business CC Account Statement", key: "cc_account_statement" },
        { title: "GST Certificate", key: "gst_certificate" },
        { title: "Udyam Certificate", key: "udyam_certificate" },
        { title: "ITR with computation", key: "itr_with_computation" },
        { title: "Audit Report", key: "audit_report" },
        { title: "GST Return", key: "gst_return" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      document_accordion: "business_loan/getDocumentsAccordion",
    }),
  },
  mounted() {
    this.getDocuments();
  },
  methods: {
    toggleDocumentAccordion() {
      //   this.document_accordion.show = !this.document_accordion.show;
      this.$store.dispatch("business_loan/setDocumentsAccordion");
      //   console.log(this.document_accordion);
    },
    toggleSubAccordion(item) {
      // console.log("sub accordion", item);
      item.show = !item.show;
    },
    viewDocument(url) {
      if (url) window.open(url, "_blank");
    },
    // returning true if image extension is png, jpg or, jpeg
    isImageDocument(doc) {
      // console.log(doc);
      if (doc) {
        let ext = doc.split(".");
        ext = ext[ext.length - 1];
        // console.log(ext);
        return ext == "png" || ext == "jpg" || ext == "jpeg";
      } else {
        this.showSnakeBar("error", "No document available");
      }
    },
    // if isImageDocument returns true then opens image in new tab
    previewImage(doc, document_type) {
      let image_url = doc;

      let route = this.$router.resolve({
        name: "view-image",
        params: {
          image: this.encript(image_url),
          document: this.encript(document_type),
        },
      });
      window.open(route.href, "_blank");
    },
    getDocuments() {
      const self = this;
      let look_up_key = `${self.decrypt(this.$route.params.id)}/`;
      const successHandler = (response) => {
        // console.log("document", response);
        this.applicants_kyc_data = response.data.result.applicants_kyc_data[0];
        this.saving_account_list = response.data.result.saving_account_list;
        this.sanctioned_letter =
          response.data.result.business_cc_details.sanctioned_letter;
        this.audit_report = response.data.result.audit_report;
        this.business_cc_details =
          response.data.result.business_cc_details.business_cc_account_statement;
        this.electricity_bill_list = response.data.result.electricity_bill_list;
        this.gst_certificate = response.data.result.gst_certificate;
        this.gst_return = response.data.result.gst_return;
        this.itr = response.data.result.itr;
        this.udyam_certificate = response.data.result.udyam_certificate;
      };
      const finallyHandler = () => {};
      self.GET_request(
        self,
        self.$urls.GET_BUSINESS_LOAN_DOCUMENTS,
        look_up_key,
        null,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
    openAddDocDialog(value) {
      if (value.key == "kyc") {
        this.$refs.upload_kyc_doc_dialog.openKycDocument(
          value,
          this.applicants_kyc_data
        );
      } else {
        this.$refs.upload_other_doc_dialog.openAddOtherDocument(value);
      }
      // console.log("openAddDocDialog", value);
    },
  },
};
</script>
<style scoped>
.field-title {
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.681);
}
.field-content {
  font-size: 14px;
  color: #000000;
  font-weight: 700;
}
.document-row:hover {
  background-color: rgba(169, 169, 169, 0.147);
}
.doc-item:hover {
  background: #c9c9c955;
}
</style>
